const MATURE_CONTENT_COOKIE_KEY = 'mature-content';

const useMatureContent = () => {
  const isMatureContentEnabled = useCookie<boolean>(MATURE_CONTENT_COOKIE_KEY, { default: () => false });

  const getIsMatureContentEnabled = computed(() => isMatureContentEnabled.value);

  const setIsMatureContentEnabled = (value: boolean) => {
    isMatureContentEnabled.value = value;
  };

  return {
    getIsMatureContentEnabled,
    setIsMatureContentEnabled,
  };
};

export { useMatureContent };
